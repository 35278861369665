import React from 'react';
import ReactPlayer from 'react-player';
import { Box } from '@mui/material';
import { keycloakInstance } from '../../modules/auth';

type Props = {
  stream?: string;
  onLoaded?: () => void;
};

export const AudioStreamPlayer: React.FC<Props> = ({ stream, onLoaded }) => {
  return (
    <Box
      sx={{
        paddingY: 2,
        paddingX: 0,
      }}
    >
      <ReactPlayer
        controls
        height="55px"
        width="100%"
        url={stream ? stream : undefined}
        config={{
          file: {
            forceHLS: true,
            forceAudio: true,
            hlsOptions: {
              xhrSetup: function (xhr: XMLHttpRequest) {
                const { token } = keycloakInstance;
                xhr.setRequestHeader('Authorization', `Bearer ${token}`);
              },
            },
          },
        }}
        onReady={onLoaded}
      />
    </Box>
  );
};
