import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SpeakerNotes } from '@mui/icons-material';
import { Box, Button, List, ListItem, Stack, Typography } from '@mui/material';
import { useModal } from '../../../../common/hooks';
import { displayDateWithHour } from '../../../../common/utils';
import { BasicModal } from '../../../../components/basic-modal';
import { RoundedButton } from '../../../../components/rounded-button';
import { useCallHistoryTranscription } from '../../hooks';
import { TranscriptionItem } from './transcription-item';
import { TimeZoneContext } from '../../../scenarios/provider/timezone-context.provider';
import Loader from 'react-loader-spinner';

type Props = {
  callId: string;
  disabled: boolean;
};

export const TranscriptionModal: React.FC<Props> = ({ callId, disabled }) => {
  const { t } = useTranslation();
  const modal = useModal();
  const { data, isLoading } = useCallHistoryTranscription(modal.isOpen ? callId : undefined);
  const { timezone } = useContext(TimeZoneContext);

  const modalBody = (
    <Stack
      justifyContent={'space-between'}
      sx={{
        height: '100%',
      }}
    >
      <Stack rowGap={2}>
        <Typography variant="body1">{`ID: ${callId}`}</Typography>
        {!data?.history.length && !isLoading && (
          <Typography variant="body1" textAlign={'center'} marginY={10}>
            {t('history.transcription.noData')}
          </Typography>
        )}
        {data?.history.length ? (
          <Box
            sx={{
              height: {
                xs: 300,
                sm: 400,
                xl: 500,
              },
              overflowY: 'auto',
            }}
          >
            <List
              sx={{
                listStyle: 'none',
                padding: 0,
                marginRight: '10px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <ListItem
                sx={{
                  px: 0,
                }}
              >
                <Typography
                  component={'p'}
                  sx={{
                    opacity: 0.7,
                    textAlign: 'center',
                  }}
                >
                  {t('history.transcription.startedLabel')}
                  {data?.history[0] && displayDateWithHour(data?.history[0].OccurredOn || '', timezone)}
                </Typography>
              </ListItem>

              {data?.history
                .sort((a, b) => new Date(a.OccurredOn).getTime() - new Date(b.OccurredOn).getTime())
                .map((entry) => (
                  <TranscriptionItem entry={entry} key={entry.OccurredOn + entry.OccurredOn + entry.Text} />
                ))}

              <ListItem
                sx={{
                  px: 0,
                }}
              >
                <Typography
                  component={'p'}
                  sx={{
                    opacity: 0.7,
                    textAlign: 'center',
                  }}
                >
                  {t('history.transcription.endedLabel')}
                  {data?.history[data?.history.length - 1].OccurredOn &&
                    displayDateWithHour(data?.history[data?.history.length - 1]?.OccurredOn || '', timezone)}
                </Typography>
              </ListItem>
            </List>
          </Box>
        ) : null}
        {isLoading && (
          <Stack
            justifyContent={'center'}
            alignItems={'center'}
            sx={{
              height: '100%',
              width: '100%',
            }}
          >
            <Loader type={'Oval'} color={'#00BFFF'} height={45} width={45} />
          </Stack>
        )}
        <Stack direction={'row'} justifyContent={'flex-end'}>
          <Button
            onClick={modal.close}
            color="primary"
            variant="contained"
            sx={{
              color: 'common.white',
            }}
          >
            {t('history.transcription.closeBtn')}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );

  return (
    <>
      <RoundedButton icon={<SpeakerNotes />} disabled={disabled} onClick={() => !isLoading && modal.open()} />
      <BasicModal
        title={t('history.transcription.heading')}
        modalBody={modalBody}
        handleModalClose={modal.close}
        modalOpen={modal.isOpen}
      />
    </>
  );
};
